@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tab-button {
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 1px solid transparent;
}

.tab-button.active {
  color: #ffa500;
  border-bottom: 3px solid #ffa500;
}

.content {
  padding: 8px;
  margin-top: 2px;
}

/* src/components/MultiSelect.css */
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 0.25rem;
}

.custom-checkbox:checked {
  border-color: #ecc551;
  background-color: #ecc551;
}

.custom-checkbox:checked::before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@layer components {
  .sidebarActiveLinkWithDropdown {
    @apply tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-5 hover:tw-cursor-pointer md:tw-bg-gray-100 tw-rounded-tl-[30px] tw-rounded-bl-[30px] tw-text-primary tw-font-semibold tw-relative tw-whitespace-nowrap;
  }

  .sidebarInactiveLinkWithDropdown {
    @apply tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-5 hover:tw-cursor-pointer tw-font-semibold tw-relative tw-whitespace-nowrap;
  }

  .sidebarActiveLink {
    @apply tw-flex tw-items-center tw-text-[14px] md:tw-text-[16px] tw-gap-3 tw-px-4 tw-py-5 hover:tw-cursor-pointer md:tw-bg-gray-100 tw-rounded-tl-[30px] tw-rounded-bl-[30px] md:tw-text-primary tw-font-semibold tw-relative tw-whitespace-nowrap;
  }

  .sidebarInactiveLink {
    @apply tw-flex tw-items-center tw-text-[14px] md:tw-text-[16px] tw-px-4 tw-gap-3 tw-py-5 hover:tw-cursor-pointer tw-font-semibold tw-whitespace-nowrap;
  }

  .sidebarDropdownActiveLink {
    @apply tw-py-3 tw-text-secondary tw-text-[14px] md:tw-text-[16px] md:tw-font-semibold tw-whitespace-nowrap;
  }

  .sidebarDropdownInctiveLink {
    @apply tw-py-3 tw-text-[14px] md:tw-text-[16px] md:tw-font-semibold tw-whitespace-nowrap;
  }

  .brandSearch .inputClass {
    @apply tw-w-full;
  }
}

.tabActive {
  background-color: #ecc551;
  box-shadow: 0px 0px 20px -11px rgba(236, 197, 81, 0.3);
  padding: 10px 27px;
  border-radius: 8px;
}

.defaultTab {
  border: 1px solid #ecc551;
  background-color: #fff;
  padding: 10px 27px;
  border-radius: 8px;
}

.tabActiveList::after {
  border-bottom: 3px solid #ecc551;
}

.tabActiveList {
  position: relative;
}

.tabActiveList::after {
  border-bottom: 3px solid #ecc551;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: -3px;
}

.tab-list2 {
  border-bottom: 3px solid #eaecf0;
}

.tabs-sec .tabs {
  background-color: #fff;
  padding: 14px 25px;
  margin-bottom: 35px;
  margin-top: 12px;
  border-radius: 8px;
}

.input input {
  width: 100%;
  outline: none;
  border: none;
  padding: 8px 8px;
}

.activePaginate {
  background-color: #ecc551;
}

.input .customSearch {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.customSearch svg {
  stroke: #455a64;
}

.investigate input {
  background-color: #f5f6f8;
}

.investigate input::placeholder {
  color: #455a64;
}

.dropdown-menu {
  box-shadow: 0px 1px 4px #80808070;
}

.custom__picker .react-datepicker__view-calendar-icon input {
  border: none;
  outline: none;
  width: 100%;
  padding: 6px 16px 5px 16px;
}

.react-datepicker__calendar-icon {
  right: 4px;
}

.report-bg {
  background: linear-gradient(to right, #fff0c4, #f5d77f);
}

.form-group input[type="checkbox"] {
  position: relative;
  border: 2px solid #d0d5dd;
  border-radius: 6px;
  background: none;
  cursor: pointer;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: 1;
}

.form-group input[type="checkbox"]:checked {
  background-color: #ecc551;
  border: 2px solid #ecc551;
  opacity: 1;
  z-index: 2;
}

.form-group input[type="checkbox"]:checked:before {
  opacity: 1;
  z-index: 2;
}

.form-group input[type="checkbox"]:before {
  content: "";
  position: absolute;
  right: 50%;
  opacity: 0;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: -1;
}

.show_side {
  display: block;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card-shadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.graph-card {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.dropdown-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.react-datepicker-wrapper {
  width: 100%;
}

@media (max-width: 768px) {
  .tw-ml-auto-md-none {
    margin-left: 0 !important;
  }
}

/* Yellow Range Slider */
#range-slider-yellow .range-slider__range {
  background: #ECC551;
  transition: height 0.3s;
}

#range-slider-yellow .range-slider__thumb {
  background: #fff;
  width: 26px;
  height: 26px;
  border-width: 7px;
  border-color: #ECC551;
  border-radius: 13px;
  transition: transform 0.3s;
}

#range-slider-yellow .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider-yellow .range-slider__range[data-active] {
  height: 12px;
}